import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'

export const isDev = process.env.NODE_ENV === 'development'
export const APP_NAME = 'BPlanner - Система управления';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const AVATARS_BASE_URL = 'https://bplanner-main-avatars.storage.yandexcloud.net';
// export const API_GATEWAY = 'https://api.bplanner.online';

export const API_GATEWAY = isDev
  ? 'http://localhost:8000'
  : 'https://bplanner-back.dev.redramka.ru'

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR
};
